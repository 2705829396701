import React, { useEffect, useState } from 'react';

/* global $ */

export default function GesHeader() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, 1000);

  
    const initializeSlickNav = () => {
      $('.main-menu').slicknav({
        prependTo: '#mobile-menu',
        label: '',
      });
    };

    if (isLoading) {
      initializeSlickNav();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <div className={`main-site ${isLoading ? 'loaded' : ''}`}>
      {/* preloader */}
      <div id="loader-wrapper">
        <div id="loader" />
        <div className="loader-section section-left" />
        <div className="loader-section section-right" />
      </div>
      {/* /End preloader */}
      {/* Header Area Start */}
      <header className="sticky-header home-3">
        <div className="container">
          <div className="row">
            <div className="col-md-2 d-flex align-items-center">
              <div className="logo">
                <a href="/ges">
                  <img src="themes/ges/assets/img/logoges.png" alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-md-10">
              <div className="main-menu float-right">
                <nav>
                  <ul>
                    <li><a href="/ges">Ana Sayfa</a></li>
                    <li><a href="/about">Hakkımızda</a></li>
                    <li><a href="/contact">İletişim</a></li>
                    <li><a href="/quote">Teklif Al</a></li>
                    <li><a href="/login" className="green">Giriş Yap<i className="ti ti-user"></i></a></li>
                  </ul>
                </nav>
              </div>
              <div id="mobile-menu" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
