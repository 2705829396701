import { createContext, useState, useContext } from "react";

const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || null);

    const login = (user) => {
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
    }

    const logout = () => {
        setUser(null);
        localStorage.removeItem("user");
    }

    const values = {
        user,
        login,
        logout,
    };

    return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const useData = () => useContext(Context);
