import React from 'react';

export default function DeleteDeviceModal({ show, handleClose, handleConfirm, deviceName }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" style={{ color: 'red' }}>Sil !</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{deviceName} adlı cihazınızı silmek istediğinize emin misiniz?</p>
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-danger" onClick={handleConfirm}>
              Evet
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleClose}>
              İptal
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
