import React from 'react'
import GesHeader from '../components/GesHeader'
import { Outlet } from 'react-router-dom'
import GesFooter from '../components/GesFooter'

export default function GesLayout() {
    return (
        <div>
            <GesHeader />
            <Outlet />
            <GesFooter />
        </div>

    )
}
