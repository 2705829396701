import React, { useState, useEffect } from 'react';

export default function EditDeviceModal({ show, handleClose, handleConfirm, device, user }) {
  const [editDevice, setEditDevice] = useState({
    deviceId: "",
    name: "",
    createdDate: "",
    status: true,
    deviceNumber: "",
    lat: "",
    lon: "",
    locationName: ""
  });

  useEffect(() => {
    if (device) {
      setEditDevice({
        deviceId: device.deviceId,
        name: device.deviceName || device.name,
        createdDate: device.createdDate,
        status: device.status,
        deviceNumber: device.deviceNumber,
        lat: device.lat,
        lon: device.lon,
        locationName: device.locationName
      });
    }
  }, [device]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditDevice(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm(editDevice);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cihazı Düzenle</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="deviceName" className="form-label">Cihaz Adı</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="deviceName"
                  name="name"
                  value={editDevice.name}
                  onChange={handleChange}
                />
              </div>
              {(user.roleId === 1 || user.roleId === 2 || user.roleId === 3) && (
                <>
                  <div className="mb-3">
                    <label htmlFor="locationName" className="form-label">Lokasyon</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="locationName"
                      name="locationName"
                      value={editDevice.locationName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lat" className="form-label">Enlem</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="lat"
                      name="lat"
                      value={editDevice.lat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lon" className="form-label">Boylam</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      id="lon"
                      name="lon"
                      value={editDevice.lon}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
              {user.roleId === 1 && (
                <>
                  <div className="mb-3">
                    <label htmlFor="deviceNumber" className="form-label">Cihaz Numarası</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="deviceNumber"
                      name="deviceNumber"
                      value={editDevice.deviceNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="createdDate" className="form-label">Tarih</label>
                    <input
                      required
                      type="date"
                      className="form-control"
                      id="createdDate"
                      name="createdDate"
                      value={editDevice.createdDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">Durum</label>
                    <select
                      required
                      className="form-control"
                      id="status"
                      name="status"
                      value={editDevice.status}
                      onChange={handleChange}
                    >
                      <option value={true}>Aktif</option>
                      <option value={false}>Pasif</option>
                    </select>
                  </div>
                </>
              )}
              <button type="submit" className="btn btn-success mr-2">Güncelle</button>
              <button type="button" className="btn btn-secondary" onClick={handleClose}>İptal</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
