import { instance } from "./base";
import axios from "axios";

export const apiPostLogin = async (email, password) => {
    try {
        const response = await instance.post('/api/Authorization/Login', {
            email,
            password,
            isRemember: true
        });
        return response.data;
    } catch (error) {
        throw new Error(`Giriş başarısız: ${error.message}`);
    }
};
export const apiPostGoogleSignIn = async ({ email, name, surname, pictureUrl, phone }) => {
    try {
        const response = await instance.post('/api/Authorization/GoogleSignIn', {
            email,
            name,
            surname,
            pictureUrl,
            phone
        });
        return response.data;
    } catch (error) {
        throw new Error(`Google ile giriş başarısız: ${error.message}`);
    }
};

export const apiPutUserInfo = async (token, user) => {
    try {
        const response = await instance.put('/api/Authorization/UpdateUserInfo', {
            name: user.name,
            surname: user.surname,
            phone: user.phone,
            email: user.email
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Kullanıcı bilgileri güncellenemedi: ${error.message}`);
    }
};

export const apiPostChangePassword = async (token, oldPassword, newPassword, confirmPassword) => {
    try {
        const response = await instance.post('/api/Authorization/ChangePassword', {
            oldPassword,
            newPassword,
            confirmPassword
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Şifre değiştirme başarısız: ${error.message}`);
    }
};

export const apiPostUserRegister = async (name, surname, phone, email, deviceNumber) => {
    try {
        const response = await instance.post('/api/Authorization/Register', {
            name,
            surname,
            phone,
            email,
            deviceNumber
        });
        return response.data;
    } catch (error) {
        throw new Error(`Kullanıcı kaydı başarısız: ${error.message}`);
    }
};


export const fetchWeatherForecast = async () => {
    const API_KEY = '893266d84a86f8050d4da0a2286e7696';
    const latitude = 37.7968911;
    const longitude = 41.067373;
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${API_KEY}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw new Error('Hava durumu verileri alınamadı.');
    }
};



export const getEnergyForecastFromChatGPT = async (weatherData, numberOfDays) => {
    const apiKey = "sk-proj-JO6RYuJxidmYvNVrrh0RT3BlbkFJBFCmQE6eJHaFTOJ1Z8SM";
    const apiUrl = "https://api.openai.com/v1/chat/completions";
    try {
        const response = await axios.post(apiUrl, {
            model: "gpt-3.5-turbo",
            messages: [
                {
                    role: "system",
                    content: "You are an energy production expert."
                },
                {
                    role: "user",
                    content: `You are provided with 5 days of weather data. Based on these 5 days, predict the expected energy production in kW for the selected date range of ${numberOfDays} days. The first 5 days should directly use the provided weather data, and for the remaining days, use trends from the existing data to make realistic estimates. The average daily energy consumption for a typical household is around 15-20 kW. The output should be in the following JSON format: {"energy_production": [{"date": "YYYY-MM-DD", "energy": value_in_kW}]}. Here is the weather data: ${JSON.stringify(weatherData)}`
                }
            ]
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
            }
        });

        const content = response.data.choices[0].message.content.trim();
        console.log("ChatGPT Response:", content);

        const parsedResponse = JSON.parse(content.match(/{[\s\S]*}/)[0]);

        return parsedResponse.energy_production;
    } catch (error) {
        console.error("ChatGPT Communication Error:", error);
        throw new Error('Failed to communicate with ChatGPT.');
    }
};



export const apiPostForgotPassword = async (email) => {
    try {
        const response = await instance.post('/api/Authorization/ForgotPassword', { email });
        return response.data;
    } catch (error) {
        throw new Error(`Şifre sıfırlama başarısız: ${error.message}`);
    }
};

export const apiSendMessage = async (fullname, email, mesaj) => {
    try {
        const response = await instance.post('http://apisolartech.softsense.com.tr/api/Communication/SendMessage', {
            fullname,
            email,
            mesaj
        });
        return response.data;
    } catch (error) {
        throw new Error(`Mesaj gönderimi başarısız: ${error.message}`);
    }
};

export const analyzeProductionDifference = async (predicted, actual) => {
    const apiKey = "sk-proj-JO6RYuJxidmYvNVrrh0RT3BlbkFJBFCmQE6eJHaFTOJ1Z8SM";
    const apiUrl = "https://api.openai.com/v1/chat/completions";

    try {
        const response = await axios.post(apiUrl, {
            model: "gpt-4-turbo", 
            messages: [
                {
                    role: "system",
                    content: "Sen bir güneş paneli enerji üretim uzmanısın."
                },
                {
                    role: "user",
                    content: `Sadece istenilen bilgileri listele ve hiçbir giriş veya yorum ekleme. Güneş panellerinin verimliliğini artırmak için uygulanabilecek en iyi yöntemleri, sıralanmış bir liste halinde, numaralandırarak ver. Her öneriyi kısa ve öz cümlelerle belirt.`
                }
            ]
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json"
            }
        });
        return response.data.choices[0].message.content;
    } catch (error) {
        throw new Error(`Yapay zeka analizi başarısız: ${error.message}`);
    }
};




export const apiGetCareTypeList = async (token) => {
    try {
        const response = await instance.get('/api/Customer/GetCareTypeList', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.careTypeModels;
    } catch (error) {
        throw new Error(`Bakım türleri getirilemedi: ${error.message}`);
    }
}
export const apiPostAddNewMaintenance = async (token, maintenance, roleId) => {
    const url = roleId === 2
        ? '/api/Manager/AddCareForManager'
        : '/api/Customer/AddCare';

    try {
        const response = await instance.post(url, {
            careTypeId: maintenance.careTypeId,
            careDate: maintenance.careDate,
            details: maintenance.details
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Yeni bakım ekleme başarısız: ${error.message}`);
    }
};

export const apiGetCareList = async (token, roleId) => {
    const url = roleId === 2
        ? '/api/Manager/GetCareListForManager'
        : '/api/Customer/GetCareList';

    try {
        const response = await instance.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.careList;
    } catch (error) {
        throw new Error(`Bakım listesi getirilemedi: ${error.message}`);
    }
};

export const apiDeleteCare = async (token, careId) => {
    try {
        const response = await instance.delete('/api/Customer/DeleteCare', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                careId: careId
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Bakım silme başarısız: ${error.message}`);
    }
};

export const apiPutUpdateCare = async (token, care) => {
    try {
        const response = await instance.put('/api/Customer/UpdateCare', {
            careId: care.careId,
            careTypeId: care.careTypeId,
            careDate: care.careDate,
            details: care.details
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Bakım güncelleme başarısız: ${error.message}`);
    }
};
