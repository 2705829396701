import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

export default function EditWorkerModal({ show, handleClose, handleConfirm, worker }) {
  const [editWorker, setEditWorker] = useState({
    workerId: 0,
    name: '',
    surname: '',
    phone: '',
    email: '',
    deviceName: '',
    deviceNumber: '', 
    locationName: '',
    lat: 0,
    lon: 0
  });

  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (worker) {
      setEditWorker({
        workerId: worker.workerId,
        name: worker.name,
        surname: worker.surname,
        phone: worker.phone,
        email: worker.email,
        deviceName: worker.deviceName,
        deviceNumber: worker.deviceNumber, 
        locationName: worker.locationName,
        lat: worker.lat,
        lon: worker.lon
      });
    }
  }, [worker]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditWorker(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'phone') {
      if (/^\d*$/.test(value)) {
        setPhoneError('');
      } else {
        setPhoneError('Telefon numarası yalnızca rakamlardan oluşmalıdır.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (editWorker.phone.length !== 11) {
      setPhoneError('Telefon numarası 11 haneli olmalıdır.');
      return;
    }

    if (isNaN(editWorker.lat) || isNaN(editWorker.lon)) {
      toast.error('Enlem ve boylam sayısal olmalıdır.');
      return;
    }

    handleConfirm(editWorker);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Personeli Düzenle</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="workerName" className="form-label">Adı</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerName"
                  name="name"
                  value={editWorker.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerSurname" className="form-label">Soyadı</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerSurname"
                  name="surname"
                  value={editWorker.surname}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerEmail" className="form-label">Email</label>
                <input
                  required
                  type="email"
                  className="form-control"
                  id="workerEmail"
                  name="email"
                  value={editWorker.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerPhone" className="form-label">Telefon</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerPhone"
                  name="phone"
                  value={editWorker.phone}
                  onChange={handleChange}
                  maxLength="11"
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="workerDeviceName" className="form-label">Cihaz Adı</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerDeviceName"
                  name="deviceName"
                  value={editWorker.deviceName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerDeviceNumber" className="form-label">Cihaz Numarası</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerDeviceNumber"
                  name="deviceNumber"
                  value={editWorker.deviceNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerLocationName" className="form-label">Lokasyon Adı</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="workerLocationName"
                  name="locationName"
                  value={editWorker.locationName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerLat" className="form-label">Enlem</label>
                <input
                  required
                  type="number"
                  step="0.000001"
                  className="form-control"
                  id="workerLat"
                  name="lat"
                  value={editWorker.lat}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="workerLon" className="form-label">Boylam</label>
                <input
                  required
                  type="number"
                  step="0.000001"
                  className="form-control"
                  id="workerLon"
                  name="lon"
                  value={editWorker.lon}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="btn btn-success mr-2">Güncelle</button>
              <button type="button" className="btn btn-secondary" onClick={handleClose}>İptal</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
