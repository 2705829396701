import { instance } from "./base";

export const apiGetMqttTopicNames = async (token) => {
    try {
        const response = await instance.get('/api/Manager/GetMqttTopicNames', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.mqttTopicNameModels;
    } catch (error) {
        throw new Error(`MQTT konu adları alınamadı: ${error.message}`);
    }
};

export const apiPostGetMqttData = async (token, topic, startDate, endDate) => {
    try {
        const response = await instance.post('/api/Manager/GetMqttData', {
            topic,
            startDate,
            endDate
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data.mqttDataModels;
    } catch (error) {
        throw new Error(`Cihazdan veriler alınamadı!`);
    }
};
