import React from 'react';

export default function GesFooter() {
  return (
    <footer className="bg-light text-dark py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase mb-4 font-weight-bold">Hakkımızda</h5>
            <p>Güneşin ışığında geleceğe aydınlık adımlar atan Solartech, yenilenebilir enerjiye geçişin lideri olarak güneş enerjisi dönüşüm sistemlerinde öncü bir rol üstleniyor. Çevre dostu ve verimli enerji çözümlerimizle daha sürdürülebilir bir dünya için çalışıyoruz.</p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase mb-4 font-weight-bold">Bağlantılar</h5>
            <ul className="list-unstyled">
              <li className="mb-2"><a href="/ges" className="text-dark">Ana Sayfa</a></li>
              <li className="mb-2"><a href="/about" className="text-dark">Hakkımızda</a></li>
              <li className="mb-2"><a href="/contact" className="text-dark">İletişim</a></li>
              <li className="mb-2"><a href="/quote" className="text-dark">Teklif Al</a></li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase mb-4 font-weight-bold">İletişim</h5>
            <ul className="list-unstyled">
              <li className="mb-2"><i className="fa fa-map-marker" aria-hidden="true"></i> Adres: Batman</li>
              <li className="mb-2"><i className="fa fa-envelope" aria-hidden="true"></i> E-posta: softsolartech@gmail.com</li>
            </ul>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 text-center">
            <div className="footer-logo mb-3">
              <a href="index.html">
                <img src="themes/ges/assets/img/logoges.png" alt="logo" className="img-fluid" />
              </a>
            </div>
            <h2 className='text-center font-weight-bold'>&copy; 2024 Solartech Tüm Hakları Saklıdır.</h2>
          </div>
        </div>
      </div>
      {/* Scroll To Top */}
      <a href="#" className="scrollup"><i className="fa fa-angle-double-up" /></a>
    </footer>
  );
}
