import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function SolarPanelChart({ data }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString.split('.').reverse().join('-')); // gg.aa.yyyy to yyyy-mm-dd
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    return (
        <ReactApexChart
            type="bar"
            options={{
                chart: {
                    height: 350,
                    type: 'bar',
                    foreColor: "#adb0bb",
                    fontFamily: 'inherit'
                },
                colors: ["#FFC300"],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: data.map((x) => formatDate(x.date)),
                    labels: {
                        style: { cssClass: "grey--text lighten-2--text fill-color" },
                    },
                },
                yaxis: {
                    title: {
                        text: 'Üretilen Elektrik (kW)',
                    },
                    labels: {
                        style: { cssClass: "grey--text lighten-2--text fill-color" },
                        formatter: (value) => `${value} kW`
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: (value) => `${value} kW`
                    }
                }
            }}
            series={[{
                name: 'Üretilen Elektrik',
                data: data.map((x) => x.electricityProduced)
            }]}
        />
    );
}
