import CustomerLayout from "../layouts/CustomerLayout";
import GesLayout from "../layouts/GesLayout";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ChangePassword from "../pages/customer/ChangePassword";
import CustomerProfile from "../pages/customer/CustomerProfile";
import Devices from "../pages/dashboard/Devices";
import Forecast from "../pages/dashboard/Forecast";
import Home from "../pages/dashboard/Home";
import About from "../pages/presentation/About";
import Contact from "../pages/presentation/Contact";
import Ges from "../pages/presentation/Ges";
import Quote from "../pages/presentation/Quote";
import Care from "../pages/dashboard/Care";
import Fault from "../pages/dashboard/Fault";
import Employees from "../pages/dashboard/Employees";
import Admin from "../pages/admin/Admin";
import AdminLayout from "../layouts/AdminLayout";
import AuthRoute from "./AuthRoute";

const router = [
  {
    path: "/",
    element: <AuthRoute />,
    children: [
      {
        path: "/",
        element: <Home />,
        index: true
      },
      {
        path: "devices",
        element: <Devices />
      },
      {
        path: "forecast",
        element: <Forecast />
      },
      {
        path: "fault",
        element: <Fault />
      },
      {
        path: "care",
        element: <Care />
      },
      {
        path: "employees",
        element: <Employees />
      }
    ]
  },
  {
    path: "profile",
    element: <CustomerLayout />,
    children: [
      {
        index: true,
        element: <CustomerProfile />
      }
    ]
  },
  {
    path: "change-password",
    element: <CustomerLayout />,
    children: [
      {
        index: true,
        element: <ChangePassword />
      }
    ]
  },
  {
    path: "ges",
    element: <GesLayout />,
    children: [
      {
        index: true,
        element: <Ges />
      }
    ]
  },
  {
    path: "about",
    element: <GesLayout />,
    children: [
      {
        index: true,
        element: <About />
      }
    ]
  },
  {
    path: "contact",
    element: <GesLayout />,
    children: [
      {
        index: true,
        element: <Contact />
      }
    ]
  },
  {
    path: "quote",
    element: <GesLayout />,
    children: [
      {
        index: true,
        element: <Quote />
      }
    ]
  },
  {
    path: "admin",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Admin />
      },
    ]

  },
  {
    path:"admindevices",
    element:<AdminLayout />,
    children:[
      {
        index: true,
        element: <Devices />
      }
    ]
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "register",
    element: <Register />
  },
  {
    path: "forgotpassword",
    element: <ForgotPassword />
  }
];

export default router;
