import React, { useState } from 'react';
import { apiPostForgotPassword } from '../../utils/apis/customer';
import toast from 'react-hot-toast';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const data = await apiPostForgotPassword(email);
      
      if (data.message.includes("Yeni şifreniz e-posta adresinize gönderildi")) {
        toast.success("Yeni şifreniz e-posta adresinize gönderildi.");
      } else if (data.message.includes("Böyle bir kullanıcı bulunamadı")) {
        toast.error("Böyle bir kullanıcı bulunamadı.");
      } else {
        toast.error("Şifre sıfırlama işlemi başarısız oldu.");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img src="themes/solartech/assets/images/logos/logo.png" width={180} alt="logo" />
                  </a>
                  <h4 className="text-center welcome-text">Şifremi Unuttum</h4>
                  <form onSubmit={handleForgotPassword}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">E-Posta</label>
                      <input 
                        type="email" 
                        className="form-control" 
                        id="exampleInputEmail1" 
                        aria-describedby="emailHelp" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="E-posta adresinizi girin" 
                      />
                    </div>
                    <button className="btn btn-primary w-100 py-3 fs-5 mb-4 rounded-2" type='submit'>Sıfırla</button>
                  </form>
                  <div className="d-flex align-items-center justify-content-center">
                    <a className="text-primary fw-bold" href="/login">Giriş Yap</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
