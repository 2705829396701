import { Outlet } from "react-router-dom";
import { useData } from '../context/Context';
import Header from "../components/Header";

const getSidebarLinks = (roleId) => {
    switch (roleId) {
        case 2: 
            return [
                { id: 1, label: "Panelim", icon: "ti ti-layout-dashboard", link: "/" },
                { id: 2, label: "Cihazlarım", icon: "ti ti-device-tablet", link: "devices" },
                { id: 3, label: "Enerji Tahmini", icon: "ti ti-bolt", link: "forecast" },
                { id: 4, label: "Üretim Analizi", icon: "ti ti-chart-line", link: "fault" },
                { id: 5, label: "Bakım Kontrolü", icon: "ti ti-tools", link: "care" },
                { id: 6, label: "Personellerim", icon: "ti ti-user", link: "employees" }
            ];
        case 3: 
            return [
                { id: 1, label: "Panelim", icon: "ti ti-layout-dashboard", link: "/" },
                { id: 2, label: "Cihazlarım", icon: "ti ti-device-tablet", link: "devices" },
                { id: 3, label: "Enerji Tahmini", icon: "ti ti-bolt", link: "forecast" },
                { id: 4, label: "Üretim Analizi", icon: "ti ti-chart-line", link: "fault" },
                { id: 5, label: "Bakım Kontrolü", icon: "ti ti-tools", link: "care" }
            ];
        default:
            return [];
    }
};

export default function RootLayout() {
    const { user } = useData();
    const links = getSidebarLinks(user?.roleId);

    return (
        <>
            <Header links={links}>
                <Outlet />
            </Header>
        </>
    );
}
