import React, { useEffect, useState } from 'react';
import { apiPostUserRegister } from '../../utils/apis/customer';
import toast from 'react-hot-toast';
import { useData } from '../../context/Context';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const { user } = useData();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [deviceNumber, setDeviceNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/login', { replace: true });
    }
  }, [user, navigate]);

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setPhone(value);
      setPhoneError('');
    } else {
      setPhoneError('Telefon numarası yalnızca rakamlardan oluşmalıdır.');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (phone.length !== 11) {
      setPhoneError('Telefon numarası 11 haneli olmalıdır.');
      return;
    }

    try {
      const data = await apiPostUserRegister(name, surname, phone, email, deviceNumber);

      if (data.message.includes("Kullanıcı kaydı başarılı")) {
        toast.success("Geçici şifreniz email adresinize gönderilmiştir.");
        navigate('/login', { replace: true });
      } else if (data.message.includes("Bu e-posta adresi zaten kayıtlı")) {
        toast.error("Bu e-posta adresi zaten kayıtlı.");
      } else {
        toast.error("Kayıt Yapılamadı");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img src="themes/solartech/assets/images/logos/logo.png" width={180} alt="logo" />
                  </a>
                  <p className="text-center welcome-text">Hoş Geldiniz</p>
                  <form onSubmit={handleRegister}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputtext1" className="form-label">Adınız</label>
                      <input type="text" className="form-control" id="exampleInputtext1" aria-describedby="textHelp" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputtext2" className="form-label">Soyadınız</label>
                      <input type="text" className="form-control" id="exampleInputtext2" aria-describedby="textHelp" value={surname} onChange={(e) => setSurname(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputPhone1" className="form-label">Telefon Numarası</label>
                      <input type="tel" className="form-control" id="exampleInputPhone1" aria-describedby="phoneHelp" value={phone} onChange={handlePhoneChange} maxLength="11" />
                      {phoneError && <div className="text-danger">{phoneError}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">E-Posta</label>
                      <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputDevice" className="form-label">Cihaz Numarası</label>
                      <input type="text" className="form-control" id="exampleInputDevice" aria-describedby="deviceHelp" value={deviceNumber} onChange={(e) => setDeviceNumber(e.target.value)} />
                    </div>
                    <button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" type='submit'>Kaydol</button>
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="fs-4 mb-0 fw-bold">Hesabın var mı?</p>
                      <a className="text-primary fw-bold ms-2" href="/login">Giriş Yap</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
