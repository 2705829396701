import React, { useEffect, useState } from 'react';
import { useData } from '../../context/Context';
import { useNavigate } from 'react-router-dom';
import { apiPostLogin, apiPostGoogleSignIn } from '../../utils/apis/customer';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useGoogleLogin } from '@react-oauth/google';

export default function Login() {
  const { user, login } = useData();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const res = await apiPostLogin(email, password);
    console.log(res);
    if (res.message === "Giriş Başarılı") {
      login({
        name: res.name,
        surname: res.surname,
        email: res.email,
        phone: res.phone,
        token: res.token,
        roleId: res.roleId 
      });
      toast.success("Giriş Başarılı");

    } else {
      toast.error("Giriş Yapılamadı");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            'Authorization': `Bearer ${tokenResponse.access_token}`,
          },
        });
  
        const userData = await userInfo.json();
  
        const res = await apiPostGoogleSignIn({
          email: userData.email,
          name: userData.given_name,
          surname: userData.family_name,
          pictureUrl: userData.picture,
          phone: userData.phone
        });
  
        if (res.message === "Giriş Başarılı") {
          login({
            name: res.name,
            surname: res.surname,
            email: res.email,
            phone: res.phone,
            token: res.token,
            roleId: res.roleId
          });
          toast.success("Giriş Başarılı");
        } else {
          toast.error("Giriş Yapılamadı");
        }
      } catch (error) {
        toast.error("Google ile giriş yapılamadı. Lütfen önce sisteme kayıt olun.");
      }
    },
    onError: (error) => {
      console.error("Google Login Error: ", error);
      toast.error("Google ile giriş yapılamadı. Lütfen önce sisteme kayıt olun.");
    },
  });
  

  return (
    <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                    <img src="themes/solartech/assets/images/logos/logo.png" width={180} alt="logo" />
                  </a>
                  <p className="text-center welcome-text">Hoş Geldiniz</p>
                  <form onSubmit={handleLogin}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">E-Posta</label>
                      <input required type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="exampleInputPassword1" className="form-label">Şifre</label>
                      <div className="password-container">
                        <input
                          required
                          type={showPassword ? "text" : "password"}
                          className="form-control password-input"
                          id="exampleInputPassword1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="password-icon"
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <div className="form-check">
                        <input className="form-check-input primary" type="checkbox" id="flexCheckChecked" checked={isRemember} onChange={(e) => setIsRemember(e.target.checked)} />
                        <label className="form-check-label text-dark" htmlFor="flexCheckChecked">
                          Beni hatırla
                        </label>
                      </div>
                      <a className="text-primary fw-bold" href="/forgotpassword">Şifremi unuttum</a>
                    </div>
                    <button className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" type='submit'>Giriş Yap</button>
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="fs-4 mb-0 fw-bold">Yeni misin?</p>
                      <a className="text-primary fw-bold ms-2" href="/register">Kayıt Ol</a>
                    </div>
                    <div className="text-center mt-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <hr className="flex-grow-1" />
                        <span className="mx-2">YA DA</span>
                        <hr className="flex-grow-1" />
                      </div>
                      <button type='button' className="btn btn-outline-secondary w-100 py-2 mb-2 d-flex align-items-center justify-content-start" onClick={loginGoogle}>
                        <img src="themes/solartech/assets/images/icons/google.svg" alt="google" width="20" height="20" className="me-3" />
                        Google ile devam et
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
