import React, { useState, useEffect } from 'react';

export default function EditCareModal({ show, handleClose, handleConfirm, care, careTypes }) {
  const [editCare, setEditCare] = useState({
    careId: '',
    careTypeId: '',
    careDate: '',
    details: ''
  });

  useEffect(() => {
    if (care) {
      const formattedDate = care.careDate.substring(0, 10);
      setEditCare({
        careId: care.id,
        careTypeId: care.careTypeId,
        careDate: formattedDate,
        details: care.details
      });
    }
  }, [care]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditCare(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm(editCare);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Bakımı Düzenle</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="careTypeId" className="form-label">Bakım Türü</label>
                <select
                  id="careTypeId"
                  className="form-select"
                  name="careTypeId"
                  value={editCare.careTypeId}
                  onChange={handleChange}
                >
                  <option value="">Bakım Türünü Seçin</option>
                  {careTypes.map((careType) => (
                    <option key={careType.id} value={careType.id}>
                      {careType.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="careDate" className="form-label">Bakım Tarihi</label>
                <input
                  type="date"
                  id="careDate"
                  className="form-control"
                  name="careDate"
                  value={editCare.careDate}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="details" className="form-label">Bakım Detayları</label>
                <textarea
                  id="details"
                  className="form-control"
                  rows="5"
                  name="details"
                  value={editCare.details}
                  onChange={handleChange}
                  style={{ resize: 'none' }}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-success mr-2">Güncelle</button>
              <button type="button" className="btn btn-secondary" onClick={handleClose}>İptal</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
