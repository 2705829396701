import { Outlet } from "react-router-dom";
import { useData } from '../context/Context';
import Header from "../components/Header";

const getSidebarLinks = (roleId) => {
    switch (roleId) {
        case 1:
            return [
                { id: 1, label: "Panelim", icon: "ti ti-layout-dashboard", link: "/admin" },
                { id: 2, label: "Cihazlarım", icon: "ti ti-device-tablet", link: "/admindevices" },
            ];
       
        default:
            return [];
    }
};

export default function AdminLayout() {
    const { user } = useData();
    const links = getSidebarLinks(user?.roleId);

    return (
        <>
            <Header links={links}>
                <Outlet />
            </Header>
        </>
    );
}
