import React from 'react'
import ContactForm from '../../components/ContactForm'

export default function Quote() {
  return (
    
    <div>
      <div className="hero-area home-7" data-scroll-index={0}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-content">
                <h3>Eviniz veya İş yeriniz İçin güneş enerjisi sistemleri konusunda en İyi fiyat teklifini almak İçin formumuzu doldurun!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero Area End!*/}
      <div className="feature-area home-3" data-scroll-index={1}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="section-title mt-4">
                <h2>Hemen Teklif Al</h2>
              </div>
            </div>

          </div>
        </div>
      </div>
  

    {/* FAQ & Conatct Area Starts*/}
    <div className="faq-contact-area home-3 section-padding" data-scroll-index={4}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
    
            </div>
          </div>
        </div>
        <div className="row pt-20">
          <div className="col-md-10 offset-md-1">
            <ContactForm/>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}
