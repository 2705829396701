import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function RealTimeConsumptionChart({ data }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString.split('.').reverse().join('-')); // gg.aa.yyyy to yyyy-mm-dd
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    return (
        <ReactApexChart
            type="area"
            options={{
                chart: {
                    height: 350,
                    type: 'area',
                    foreColor: "#adb0bb",
                    fontFamily: 'inherit'
                },
                colors: ["#FF5733"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                title: {
                    text: 'Gerçek Zamanlı Tüketim',
                    align: 'left'
                },
                grid: {
                    borderColor: "rgba(0,0,0,0.1)",
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: data.map((x) => formatDate(x.date)),
                    labels: {
                        style: { cssClass: "grey--text lighten-2--text fill-color" },
                    },
                },
                yaxis: {
                    title: {
                        text: 'Tüketim (kW)',
                    },
                    labels: {
                        style: { cssClass: "grey--text lighten-2--text fill-color" },
                        formatter: (value) => `${value} kW`
                    }
                },
                tooltip: {
                    y: {
                        formatter: (value) => `${value} kW`
                    }
                }
            }}
            series={[{
                name: 'Tüketim',
                data: data.map((x) => x.consumption)
            }]}
        />
    );
}
