import React from 'react'

export default function About() {
  return (
    <div>
      <div className="hero-area home-5" data-scroll-index={0}>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="hero-content">
              <h3>Biz Kimiz?</h3>
              <p>Batman merkezli firmamız, yenilikçi ve çevre dostu güneş enerjisi çözümleri sunarak enerji verimliliğinde öncü olmayı hedeflemektedir. İleri teknolojiye dayalı ürünlerimiz ve uzman ekibimizle, müşterilerimize en verimli ve güvenilir güneş enerjisi ünitelerini sunuyoruz. Sürdürülebilir bir geleceğe katkıda bulunmak için sürekli olarak inovasyon yapıyor ve enerji maliyetlerinizi düşürmek için en yeni çözümleri geliştiriyoruz.  </p>
            </div>
          </div>
        </div>
      </div>
    </div>
     
      
      <div className="feature-area home-3" data-scroll-index={1}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="section-title mt-4">
                <h2>Güneşin Gücüyle Aydınlanan Bir Gelecek!</h2>
                <p>Yarının Teknolojisi, Bugünün Güneşinde</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="feature-single-image">
                <img src="themes/ges/assets/img/gezgin4.png" alt="image" />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
