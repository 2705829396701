import React, { useState, useEffect } from 'react';
import { fetchWeatherForecast, getEnergyForecastFromChatGPT } from '../../utils/apis/customer';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';

export default function Forecast() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; 

    const formatToTurkishDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        setStartDate(formatToTurkishDate(selectedDate));
    };

    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        setEndDate(formatToTurkishDate(selectedDate));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setForecast([]);

        if (!startDate || !endDate) {
            toast.error('Lütfen hem başlangıç hem de bitiş tarihlerini seçin!');
            return;
        }

        setLoading(true);

        try {
            const weatherData = await fetchWeatherForecast();
            const filteredData = weatherData.list.filter((data) => {
                const date = new Date(data.dt_txt);
                return date >= new Date(startDate.split('.').reverse().join('-')) && date <= new Date(new Date(endDate.split('.').reverse().join('-')).setHours(23, 59, 59, 999));
            });

            const numberOfDays = Math.ceil((new Date(endDate.split('.').reverse().join('-')) - new Date(startDate.split('.').reverse().join('-'))) / (1000 * 60 * 60 * 24)) + 1;

            const chatGptResponse = await getEnergyForecastFromChatGPT(filteredData, numberOfDays);
            setForecast(chatGptResponse);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const chartOptions = {
        chart: {
            type: 'bar',
        },
        xaxis: {
            categories: forecast.map(item => formatToTurkishDate(item.date)),
            labels: {
                formatter: (value) => value
            }
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 10,
                            color: '#13deb9'
                        },
                        {
                            from: 10,
                            to: 20,
                            color: '#ffc107'
                        },
                        {
                            from: 20,
                            to: 100,
                            color: '#dc3545'
                        }
                    ]
                },
                columnWidth: '80%',
            },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Enerji Üretim Tahmini',
        },
        series: [{
            name: 'Enerji Üretimi (kW)',
            data: forecast.map(item => parseFloat(item.energy))
        }]
    };

    const totalEnergy = forecast.reduce((acc, curr) => acc + parseFloat(curr.energy), 0).toFixed(2);
    const averageEnergy = (forecast.length ? (totalEnergy / forecast.length).toFixed(2) : 0);

    return (
        <div className="container mt-5">
            <h2><i className='ti ti-bolt'></i>Enerji Üretim Tahmini</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="startDate" className="form-label">Başlangıç Tarihi</label>
                        <input type="date" className="form-control" id="startDate" onChange={handleStartDateChange} min={formattedToday} />
                        
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="endDate" className="form-label">Bitiş Tarihi</label>
                        <input type="date" className="form-control" id="endDate" onChange={handleEndDateChange} min={formattedToday} />
                        
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Tahmin Yap</button>
            </form>
            {loading && (
                <div className="mt-4 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <div className="mt-4">
                <h4>Enerji Üretim Seviyeleri</h4>
                <div className="d-flex justify-content-around">
                    <div className="text-center">
                        <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#13deb9' }}></div>
                        <p>0-10 kW <br /> (Düşük)</p>
                    </div>
                    <div className="text-center">
                        <div className="text-dark rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#ffc107' }}></div>
                        <p>10-20 kW <br /> (Orta)</p>
                    </div>
                    <div className="text-center">
                        <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#dc3545' }}></div>
                        <p>20+ kW <br /> (Yüksek)</p>
                    </div>
                </div>
            </div>
            {forecast.length > 0 && (
                <div className="mt-4">
                    <h4>Tahmin Sonuçları:</h4>
                    <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        height="350"
                    />
                    <div className="row mt-4">
                        <div className="col-md-6 mb-3">
                            <div className="card shadow-sm border-0">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-weight-bold text-uppercase">Toplam Enerji Üretimi</h5>
                                    <p className="card-text display-5 text-success uretim">{totalEnergy} kW</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card shadow-sm border-0">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-weight-bold text-uppercase">Ortalama Enerji Üretimi</h5>
                                    <p className="card-text display-5 text-warning uretim">{averageEnergy} kW</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {error && (
                <div className="mt-4 alert alert-danger">
                    <h4>Hata:</h4>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}
