import React, { useEffect, useState } from 'react';
import { apiGetWorkers, apiPostAddNewWorker, apiPutUpdateWorker, apiDeleteWorker } from '../../utils/apis/employeesManagment';
import { apiGetDevices } from '../../utils/apis/devicesManagment'; 
import { useData } from '../../context/Context';
import toast from 'react-hot-toast';
import { FaEdit, FaTrash, FaPlusCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import EditWorkerModal from '../../components/EditWorkerModal';
import DeleteWorkerModal from '../../components/DeleteWorkerModal';

export default function Employees() {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [newWorker, setNewWorker] = useState({ 
    name: "", 
    surname: "", 
    email: "", 
    phone: "", 
    deviceNumber: "" 
  });
  const [devices, setDevices] = useState([]);
  const [openWorkers, setOpenWorkers] = useState({});
  const { user } = useData();

  useEffect(() => {
    const fetchWorkersAndDevices = async () => {
      if (!user || !user.token) {
        console.error('Kullanıcı token bulunamadı');
        setLoading(false);
        return;
      }

      try {
        const [workersData, devicesData] = await Promise.all([
          apiGetWorkers(user.token),
          apiGetDevices(user.token, 2) 
        ]);

        console.log("Workers Data:", workersData); 
        console.log("Devices Data:", devicesData); 

        if (workersData && workersData.workerModels) {
          setWorkers(workersData.workerModels);
        } else {
          console.error('Beklenmeyen personel API yanıt formatı');
        }

        if (devicesData && devicesData.devices) {
          setDevices(devicesData.devices);
        } else {
          console.error('Beklenmeyen cihaz API yanıt formatı');
          setDevices([]); 
        }

        setLoading(false);
      } catch (error) {
        console.error('API isteği hatası:', error);
        setLoading(false);
      }
    };

    fetchWorkersAndDevices();
  }, [user]);

  const handleAddWorker = async (e) => {
    e.preventDefault();
    try {
      const data = await apiPostAddNewWorker(user.token, newWorker);
      if (data.message === "Çalışan başarıyla eklendi.") {
        toast.success("Personel başarıyla eklendi.");
        toast.success("Personel e-postasına şifre gönderildi.");
  
        const newAddedWorker = {
          workerId: data.workerId,
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          deviceNumber: data.deviceNumber,
          deviceName: data.deviceName,
          locationName: data.locationName,
          lat: data.lat,
          lon: data.lot,
        };
  
        setWorkers((prevWorkers) => [...prevWorkers, newAddedWorker]);
        setAdding(false);
        setNewWorker({ 
          name: "", 
          surname: "", 
          email: "", 
          phone: "", 
          deviceNumber: "" 
        });
      } else {
        toast.error(data.message || "Personel eklenirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Personel eklenirken bir hata oluştu:", error);
      toast.error("Personel eklenirken bir hata oluştu: " + error.message);
    }
  };

  const handleEditWorker = (worker) => {
    setSelectedWorker(worker);
    setEditing(true);
  };

  const handleUpdateWorker = async (updatedWorker) => {
    try {
      const response = await apiPutUpdateWorker(user.token, updatedWorker);
      if (response.code === "200") {
        toast.success("Personel başarıyla güncellendi.");
        setWorkers((prevWorkers) =>
          prevWorkers.map((worker) => (worker.workerId === updatedWorker.workerId ? updatedWorker : worker))
        );
        setEditing(false);
        setSelectedWorker(null);
      } else {
        toast.error(response.message || "Personel güncellenirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Personel güncellenirken bir hata oluştu:", error);
      toast.error("Personel güncellenirken bir hata oluştu: " + error.message);
    }
  };

  const handleDeleteWorker = async () => {
    try {
      const response = await apiDeleteWorker(user.token, selectedWorker.workerId);
      if (response.message === "Çalışan ve ilişkili veriler başarıyla silindi.") {
        toast.success("Personel başarıyla silindi.");
        setWorkers((prevWorkers) =>
          prevWorkers.filter((worker) => worker.workerId !== selectedWorker.workerId)
        );
        setDeleting(false);
        setSelectedWorker(null);
      } else {
        toast.error(response.message || "Personel silinirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Personel silinirken bir hata oluştu:", error);
      toast.error("Personel silinirken bir hata oluştu: " + error.message);
    }
  };

  const toggleAccordion = (workerId) => {
    setOpenWorkers((prevOpenWorkers) => ({
      ...prevOpenWorkers,
      [workerId]: !prevOpenWorkers[workerId],
    }));
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <div className="spinner-border" role="status">
          <span className="sr-only">Yükleniyor...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4"><i className='ti ti-user'></i>Personel Listesi</h2>
      <div className="row">
        {workers.length === 0 ? (
          <div className="col-12">
            <p className="text-center">Personel bilgisi bulunamadı.</p>
          </div>
        ) : (
          workers.map((worker) => (
            <div className="col-md-4 mb-4" key={worker.workerId}>
              <div className="card h-100">
                <div className="card-body d-flex flex-column">
                  <p className="card-text"><strong>Adı:</strong> {worker.name}</p>
                  <p className="card-text"><strong>Soyadı:</strong> {worker.surname}</p>
                  <p className="card-text"><strong>Email:</strong> {worker.email}</p>
                  <p className="card-text"><strong>Telefon:</strong> {worker.phone}</p>
                  <button className="btn btn-link p-0 mb-3" style={{ marginRight: 'auto' }} onClick={() => toggleAccordion(worker.workerId)}>
                    {openWorkers[worker.workerId] ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  <div className={`accordion-collapse collapse ${openWorkers[worker.workerId] ? 'show' : ''}`}>
                    <p className="card-text"><strong>Cihaz Adı:</strong> {worker.deviceName}</p>
                    <p className='card-text'><strong>Cihaz Numarası:</strong> {worker.deviceNumber}</p>
                    <p className="card-text"><strong>Lokasyon Adı:</strong> {worker.locationName}</p>
                    <p className="card-text"><strong>Koordinatlar:</strong></p>
                    <hr />
                    <p className="card-text">Enlem: {worker.lat}</p>
                    <p className="card-text">Boylam: {worker.lon}</p>
                    <hr />
                  </div>
                  <div className="mt-auto d-flex justify-content-end">
                    <button className="btn btn-primary mr-2 d-flex align-items-center" onClick={() => handleEditWorker(worker)}>
                      <FaEdit className="mr-1" /> Düzenle
                    </button>
                    <button className="btn btn-danger d-flex align-items-center" onClick={() => { setSelectedWorker(worker); setDeleting(true); }}>
                      <FaTrash className="mr-1" /> Sil
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {adding && (
        <form onSubmit={handleAddWorker} className="my-4">
          <div className="mb-3">
            <label htmlFor="workerName" className="form-label">Adı</label>
            <input
              required
              type="text"
              className="form-control"
              id="workerName"
              value={newWorker.name}
              placeholder='Adı'
              onChange={(e) => setNewWorker({ ...newWorker, name: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workerSurname" className="form-label">Soyadı</label>
            <input
              required
              type="text"
              className="form-control"
              id="workerSurname"
              value={newWorker.surname}
              placeholder='Soyadı'
              onChange={(e) => setNewWorker({ ...newWorker, surname: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workerEmail" className="form-label">Email</label>
            <input
              required
              type="email"
              className="form-control"
              id="workerEmail"
              value={newWorker.email}
              placeholder='Email'
              onChange={(e) => setNewWorker({ ...newWorker, email: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workerPhone" className="form-label">Telefon</label>
            <input
              required
              type="text"
              className="form-control"
              id="workerPhone"
              value={newWorker.phone}
              placeholder='Telefon'
              onChange={(e) => setNewWorker({ ...newWorker, phone: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workerDeviceNumber" className="form-label">Cihaz Numarası</label>
            <select
              required
              className="form-control"
              id="workerDeviceNumber"
              value={newWorker.deviceNumber}
              onChange={(e) => setNewWorker({ ...newWorker, deviceNumber: e.target.value })}
            >
              <option value="">Cihaz Numarası Seçin</option>
              {devices.map(device => (
                <option key={device.deviceNumber} value={device.deviceNumber}>{device.deviceNumber}</option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn btn-success mr-2">Ekle</button>
          <button type="button" className="btn btn-danger" onClick={() => setAdding(false)}>İptal</button>
        </form>
      )}
      {!adding && (
        <div className="text-center my-4">
          <button className="btn btn-success d-flex align-items-center" onClick={() => setAdding(true)}>
            <FaPlusCircle className="mr-2" /> Personel Ekle
          </button>
        </div>
      )}
      <EditWorkerModal
        show={editing}
        handleClose={() => setEditing(false)}
        worker={selectedWorker}
        handleConfirm={handleUpdateWorker}
      />
      <DeleteWorkerModal
        show={deleting}
        handleClose={() => setDeleting(false)}
        handleConfirm={handleDeleteWorker}
        workerName={selectedWorker ? `${selectedWorker.name} ${selectedWorker.surname}` : ''}
      />
    </div>
  );
}
