import { instance } from "./base";

export const apiGetTotalDevices = async (token) => {
    try {
        const response = await instance.get('/api/Admin/GetTotalDevices', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Toplam cihaz sayısı alınamadı: ${error.message}`);
    }
};

export const apiGetUsedDevicesCount = async (token) => {
    try {
        const response = await instance.get('/api/Admin/GetUsedDevicesCount', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Kullanılan cihaz sayısı alınamadı: ${error.message}`);
    }
};

export const apiGetTotalManagers = async (token) => {
    try {
        const response = await instance.get('/api/Admin/GetTotalManagers', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Toplam müdür sayısı alınamadı: ${error.message}`);
    }
};

export const apiGetTotalEmployees = async (token) => {
    try {
        const response = await instance.get('/api/Admin/GetTotalEmployees', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Toplam personel sayısı alınamadı: ${error.message}`);
    }
};
