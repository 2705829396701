import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { apiPostGetMqttData } from '../../utils/apis/mqtt';
import { useData } from '../../context/Context';
import ReactApexChart from 'react-apexcharts';
import SolarPanelChart from '../../components/SolarPanelChart';
import RealTimeConsumptionChart from '../../components/RealTimeConsumptionChart';

export default function Home() {
    const [weatherData, setWeatherData] = useState([]);
    const today = new Date();

    const [startDateTime, setStartDateTime] = useState(`${format(today, 'yyyy-MM-dd')}T00:00`);
    const [endDateTime, setEndDateTime] = useState(`${format(today, 'yyyy-MM-dd')}T23:59`);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { user } = useData();
    const token = user ? user.token : '';

    const relevantTopics = ["temperature", "humidity", "rainfall", "windSpeed"];
    useEffect(() => {
        const fetchData = async () => {
            setError('');
            setLoading(true);
            try {
                const weatherDataPromises = relevantTopics.map(topic =>
                    apiPostGetMqttData(token, topic, startDateTime, endDateTime)
                );

                const weatherDataResponses = await Promise.all(weatherDataPromises);

                const formattedWeatherData = weatherDataResponses.reduce((acc, response, index) => {
                    const topicName = relevantTopics[index];
                    response.forEach(item => {
                        const dateTime = item.createdDate.split('.')[0];
                        if (!acc[dateTime]) {
                            acc[dateTime] = { dateTime };
                        }
                        acc[dateTime][topicName] = parseFloat(item.payload);
                    });
                    return acc;
                }, {});

                const formattedWeatherDataArray = Object.values(formattedWeatherData)
                    .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
                    .map(data => ({
                        dateTime: data.dateTime,
                        temperature: data.temperature || 0,
                        humidity: data.humidity || 0,
                        rainfall: data.rainfall || 0,
                        windSpeed: data.windSpeed || 0
                    }));

                setWeatherData(formattedWeatherDataArray);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [startDateTime, endDateTime, token]);

    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleDateTimeChange = (e, isStart) => {
        const value = e.target.value;
        if (isStart) {
            setStartDateTime(value);
        } else {
            setEndDateTime(value);
        }
    };

    const chartOptions = {
        chart: {
            type: 'line',
            height: 450,
            zoom: { enabled: true },
            foreColor: "#adb0bb",
            fontFamily: 'inherit'
        },
        colors: ["#FF5733", "#33B5FF", "#66FF33", "#FFA500"],
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', width: 3 },
        title: { text: 'Saatlik Hava Durumu', align: 'left' },
        grid: {
            borderColor: "rgba(0,0,0,0.1)",
            row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }
        },
        xaxis: {
            categories: weatherData.map(x => formatDate(x.dateTime)),
            labels: { style: { cssClass: "grey--text lighten-2--text fill-color" } }
        },
        yaxis: [{
            title: { text: 'Sıcaklık (°C)' },
            labels: { style: { cssClass: "grey--text lighten-2--text fill-color" }, formatter: (value) => `${value}°C` }
        }, {
            opposite: true,
            title: { text: 'Nem (%)' },
            labels: { style: { cssClass: "grey--text lighten-2--text fill-color" }, formatter: (value) => `${value}%` }
        }, {
            opposite: true,
            title: { text: 'Yağış (mm)' },
            labels: { style: { cssClass: "grey--text lighten-2--text fill-color" }, formatter: (value) => `${value} mm` }
        }, {
            opposite: true,
            title: { text: 'Rüzgar Hızı (km/s)' },
            labels: { style: { cssClass: "grey--text lighten-2--text fill-color" }, formatter: (value) => `${value} km/s` }
        }],
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value, { seriesIndex }) => {
                    switch (seriesIndex) {
                        case 0:
                            return `${value}°C`;
                        case 1:
                            return `${value}%`;
                        case 2:
                            return `${value} mm`;
                        case 3:
                            return `${value} km/s`;
                        default:
                            return value;
                    }
                }
            }
        }
    };

    const chartSeries = [{
        name: "Sıcaklık",
        data: weatherData.map(x => x.temperature || 0)
    }, {
        name: "Nem",
        data: weatherData.map(x => x.humidity || 0)
    }, {
        name: "Yağış",
        data: weatherData.map(x => x.rainfall || 0)
    }, {
        name: "Rüzgar Hızı",
        data: weatherData.map(x => x.windSpeed || 0)
    }];

    const solarData = [
        { date: "01.08.2024", electricityProduced: 20 },
        { date: "02.08.2024", electricityProduced: 25 },
        { date: "03.08.2024", electricityProduced: 30 },
        { date: "04.08.2024", electricityProduced: 20 },
        { date: "05.08.2024", electricityProduced: 40 },
        { date: "06.08.2024", electricityProduced: 35 },
        { date: "07.08.2024", electricityProduced: 50 },
        { date: "08.08.2024", electricityProduced: 45 },
        { date: "09.08.2024", electricityProduced: 60 },
        { date: "10.08.2024", electricityProduced: 45 },
        { date: "11.08.2024", electricityProduced: 45 },
        { date: "12.08.2024", electricityProduced: 38 },
        { date: "13.08.2024", electricityProduced: 65 },
        { date: "14.08.2024", electricityProduced: 50 },
    ];

    const realTimeConsumptionData = [
        { date: "01.08.2024", consumption: 100 },
        { date: "02.08.2024", consumption: 110 },
        { date: "03.08.2024", consumption: 105 },
        { date: "04.08.2024", consumption: 95 },
        { date: "05.08.2024", consumption: 45 },
        { date: "06.08.2024", consumption: 25 },
        { date: "07.08.2024", consumption: 15 },
        { date: "08.08.2024", consumption: 25 },
        { date: "09.08.2024", consumption: 35 },
        { date: "10.08.2024", consumption: 15 },
        { date: "11.08.2024", consumption: 85 },
        { date: "12.08.2024", consumption: 35 },
        { date: "13.08.2024", consumption: 25 },
        { date: "14.08.2024", consumption: 85 },
        { date: "15.08.2024", consumption: 55 },
    ];

    const filterDataByDateRange = (data, dateKey) => {
        return data.filter(item => {
            const itemDate = new Date(item[dateKey].split('.').reverse().join('-'));
            return itemDate >= new Date(startDateTime) && itemDate <= new Date(endDateTime);
        });
    };

    const filteredSolarData = filterDataByDateRange(solarData, 'date');
    const filteredConsumptionData = filterDataByDateRange(realTimeConsumptionData, 'date');

    return (
        <div className="container mt-5">
            <h2><i className='ti ti-cloud'></i> Hava Durumu</h2>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label htmlFor="startDateTime" className="form-label">Başlangıç Tarihi ve Saati</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        id="startDateTime"
                        value={startDateTime}
                        onChange={(e) => handleDateTimeChange(e, true)}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label htmlFor="endDateTime" className="form-label">Bitiş Tarihi ve Saati</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        id="endDateTime"
                        value={endDateTime}
                        onChange={(e) => handleDateTimeChange(e, false)}
                    />
                </div>
            </div>

            {loading && (
                <div className="mt-4 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {error && (
                <div className="mt-4 alert alert-danger">
                    <h4>Hata:</h4>
                    <p>{error}</p>
                </div>
            )}

            <div className="row mb-5 mt-4">
                <div className="w-100 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body">
                            <ReactApexChart
                                options={chartOptions}
                                series={chartSeries}
                                type="line"
                                height={450}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="w-100 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body">
                            <div className="d-sm-flex d-block align-items-center justify-content-between mb-3">
                                <div className="mb-3 mb-sm-0">
                                    <h5 className="card-title fw-semibold">Güneş Paneli Durumu</h5>
                                </div>
                            </div>
                            <SolarPanelChart data={filteredSolarData} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="w-100 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body">
                            <div className="d-sm-flex d-block align-items-center justify-content-between mb-3">
                                <div className="mb-3 mb-sm-0">
                                    <h5 className="card-title fw-semibold">Gerçek Zamanlı Tüketim</h5>
                                </div>
                            </div>
                            <RealTimeConsumptionChart data={filteredConsumptionData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
