import React from 'react';
import { useData } from '../context/Context';
import { Navigate, Outlet } from 'react-router-dom';
import RootLayout from '../layouts/RootLayout';

const AuthRoute = () => {
  const { user } = useData();

  if (!user) {
    return <Navigate to="/ges" replace />;
  }

  if (user.roleId === 1) {
    return <Navigate to="/admin" replace />;
  } else {
    return <RootLayout><Outlet /></RootLayout>;
  }
};

export default AuthRoute;
