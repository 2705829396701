import React, { useState } from 'react';
import { apiSendMessage } from '../utils/apis/customer';
import toast, { Toaster } from 'react-hot-toast';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    mesaj: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiSendMessage(formData.fullname, formData.email, formData.mesaj);
      if (response.message === "Mesajınız başarıyla gönderildi") {
        toast.success("Mesajınız başarıyla gönderildi");
        setFormData({
          fullname: '',
          email: '',
          mesaj: ''
        });
      }
    } catch (error) {
      toast.error("Mesaj gönderilemedi, lütfen tekrar deneyin.");
    }
  };

  return (
    <div className="contact-form-wrapper">
      <Toaster />
      <div className="contact-form">
        <form id="contact-form" className="custom-form" onSubmit={handleSubmit}>
          <div className="controls">
            <div className="form-group">
              <label htmlFor="form_name">Ad - Soyad *</label>
              <input
                id="form_name"
                type="text"
                name="fullname"
                className="form-control bg-white"
                placeholder="Ad - Soyad *"
                required="required"
                data-error="Name is required."
                value={formData.fullname}
                onChange={handleChange}
              />
              <div className="help-block with-errors"></div>
            </div>
            <div className="form-group">
              <label htmlFor="form_email">E-Posta *</label>
              <input
                id="form_email"
                type="email"
                name="email"
                className="form-control bg-white"
                placeholder="E-Posta *"
                required="required"
                data-error="Valid email is required."
                value={formData.email}
                onChange={handleChange}
              />
              <div className="help-block with-errors"></div>
            </div>
            <div className="form-group">
              <label htmlFor="form_message">Mesajınız*</label>
              <textarea
                id="form_message"
                name="mesaj"
                className="form-control bg-white"
                placeholder="Mesajınız*"
                rows={4}
                required="required"
                data-error="Please, leave us a message."
                value={formData.mesaj}
                onChange={handleChange}
                style={{ resize: 'none' }}
              />
              <div className="help-block with-errors"></div>
            </div>
            <input type="submit" className="btn btn-primary" value="Gönder" />
          </div>
        </form>
      </div>
    </div>
  );
}
