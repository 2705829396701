import React, { useEffect, useState } from 'react';
import { apiPostAddNewDevice, apiGetDevices, apiPutUpdateDevice, apiDeleteDevice } from '../../utils/apis/devicesManagment';
import { useData } from '../../context/Context';
import toast from 'react-hot-toast';
import { FaEdit, FaTrash, FaPlusCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import DeleteDeviceModal from '../../components/DeleteDeviceModal';
import EditDeviceModal from '../../components/EditDeviceModal';
import Pagination from '../../components/Pagination';

export default function Devices() {
  const { user } = useData();
  const [devices, setDevices] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [adding, setAdding] = useState(false);
  const [newDevice, setNewDevice] = useState({ deviceNumber: "" });
  const [editDevice, setEditDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [openDevices, setOpenDevices] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(6);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const res = await apiGetDevices(user.token, user.roleId, pageNumber, pageSize);
        setDevices(res.devices || []);
        setTotalDevices(res.total || 1);
        console.log(res)
      } catch (error) {
        toast.error("Cihazlar alınırken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [user.token, user.roleId, pageNumber, pageSize]);

  const handleAddDevice = async (e) => {
    e.preventDefault();
    try {
      const data = await apiPostAddNewDevice(user.token, user.roleId, newDevice);
      if (data.message === "Cihaz başarıyla müdüre eklendi ve ilişkilendirildi." || data.message === "Cihaz başarıyla eklendi.") {
        toast.success("Cihazınız Eklendi.");
        setDevices((prevDevices) => [
          ...prevDevices,
          {
            deviceId: data.deviceId,
            deviceNumber: data.deviceNumber,
            deviceName: data.deviceName,
            locationName: data.locationName,
            lat: data.lat,
            lon: data.lon,
            createdDate: data.createdDate,
            status: data.status
          },
        ]);
        setAdding(false);
        setNewDevice({ deviceNumber: "" });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Cihaz eklenirken bir hata oluştu.");
    }
  };

  const handleEditDevice = async (device) => {
    try {
      const data = await apiPutUpdateDevice(user.token, user.roleId, device);
      toast.success(data.message || "Cihaz başarıyla güncellendi.");
      setDevices((prevDevices) =>
        prevDevices.map((d) =>
          d.deviceId === device.deviceId ? { ...d, ...device } : d
        )
      );
      setShowEditModal(false);
    } catch (error) {
      toast.error(error.message || "Cihaz güncellenirken bir hata oluştu.");
    }
  };

  const handleDeleteDevice = async () => {
    try {
      const data = await apiDeleteDevice(user.token, user.roleId, deviceToDelete.deviceId);
      toast.success("Cihaz başarıyla silindi.");
      setDevices((prevDevices) =>
        prevDevices.filter((device) => device.deviceId !== deviceToDelete.deviceId)
      );
      setShowModal(false);
    } catch (error) {
      toast.error("Cihaz silinirken bir hata oluştu.");
    }
  };

  const openDeleteModal = (device) => {
    setDeviceToDelete(device);
    setShowModal(true);
  };

  const openEditModal = (device) => {
    setEditDevice(device);
    setShowEditModal(true);
  };

  const toggleAccordion = (deviceId) => {
    setOpenDevices((prevOpenDevices) => ({
      ...prevOpenDevices,
      [deviceId]: !prevOpenDevices[deviceId],
    }));
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const totalPages = Math.ceil(totalDevices / pageSize);

  return (
    <div className="container">
      <h1 className="text-center my-4"><i className='ti ti-device-tablet'></i>Cihazlarım</h1>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Yükleniyor...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            {devices.length === 0 ? (
              <div className="col-12">
                <p className="text-center">Cihaz bilgisi bulunamadı.</p>
              </div>
            ) : (
              devices.map((device) => (
                <div key={device.deviceId} className="col-md-4 mb-4">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column">
                      <p className="card-text"><strong>Cihaz Adı:</strong> {device.deviceName}</p>
                      <p className="card-text"><strong>Cihaz Numarası:</strong> {device.deviceNumber}</p>
                      <p className="card-text"><strong>Lokasyon:</strong> {device.locationName}</p>
                      <button className="btn btn-link p-0" style={{ marginRight: 'auto' }} onClick={() => toggleAccordion(device.deviceId)}>
                        {openDevices[device.deviceId] ? <FaChevronUp /> : <FaChevronDown />}
                      </button>
                      <div className={`accordion-collapse collapse ${openDevices[device.deviceId] ? 'show' : ''}`}>
                        <hr />
                        <p className="card-text"><strong>Koordinatlar:</strong></p>
                        <p className="card-text">Enlem: {device.lat}</p>
                        <p className="card-text">Boylam: {device.lon}</p>
                        <hr />
                        <p className="card-text">Tarih: {new Date(device.createdDate).toLocaleDateString()}</p>
                      </div>
                      <div className="mt-auto d-flex justify-content-end">
                        <button
                          className="btn btn-primary mr-2 d-flex align-items-center"
                          onClick={() => openEditModal(device)}
                        >
                          <FaEdit className="mr-2" /> Düzenle
                        </button>
                        <button
                          className="btn btn-danger d-flex align-items-center"
                          onClick={() => openDeleteModal(device)}
                        >
                          <FaTrash className="mr-2" /> Sil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {(user.roleId === 1 || user.roleId === 2) && (
            <>
              {adding && (
                <form onSubmit={handleAddDevice} className="my-4">
                  <div className="mb-3">
                    <label htmlFor="deviceNumber" className="form-label">Cihaz Numarası</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="deviceNumber"
                      value={newDevice.deviceNumber}
                      placeholder='Lütfen bir cihaz numarası giriniz'
                      onChange={(e) => setNewDevice({ ...newDevice, deviceNumber: e.target.value })}
                    />
                  </div>
                  {user.roleId === 1 && (
                    <>
                      <div className="mb-3">
                        <label htmlFor="deviceName" className="form-label">Cihaz Adı</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="deviceName"
                          value={newDevice.deviceName}
                          placeholder='Lütfen bir cihaz adı giriniz'
                          onChange={(e) => setNewDevice({ ...newDevice, deviceName: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="locationName" className="form-label">Lokasyon</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="locationName"
                          value={newDevice.locationName}
                          placeholder='Lütfen bir lokasyon adı giriniz'
                          onChange={(e) => setNewDevice({ ...newDevice, locationName: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lat" className="form-label">Enlem</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="lat"
                          value={newDevice.lat}
                          placeholder='Lütfen enlem giriniz'
                          onChange={(e) => setNewDevice({ ...newDevice, lat: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lon" className="form-label">Boylam</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="lon"
                          value={newDevice.lon}
                          placeholder='Lütfen boylam giriniz'
                          onChange={(e) => setNewDevice({ ...newDevice, lon: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="createdDate" className="form-label">Tarih</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          id="createdDate"
                          value={newDevice.createdDate}
                          placeholder='Lütfen bir tarih giriniz'
                          onChange={(e) => setNewDevice({ ...newDevice, createdDate: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">Durum</label>
                        <select
                          required
                          className="form-control"
                          id="status"
                          value={newDevice.status}
                          onChange={(e) => setNewDevice({ ...newDevice, status: e.target.value })}
                        >
                          <option value={true}>Aktif</option>
                          <option value={false}>Pasif</option>
                        </select>
                      </div>
                    </>
                  )}
                  <button type="submit" className="btn btn-success mr-2">Ekle</button>
                  <button className="btn btn-danger" onClick={() => setAdding(false)}>İptal</button>
                </form>
              )}
              {!adding && (
                <div className="text-center my-4">
                  <button className="btn btn-success d-flex align-items-center" onClick={() => setAdding(true)}>
                    <FaPlusCircle className="mr-2" /> Cihaz Ekle
                  </button>
                </div>
              )}
            </>
          )}
          {user.roleId === 1 && (
            <Pagination
              count={totalPages}
              page={pageNumber}
              onChange={handlePageChange}
            />
          )}
        </>
      )}
      <DeleteDeviceModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDeleteDevice}
        deviceName={deviceToDelete?.deviceName}
      />
      <EditDeviceModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleConfirm={handleEditDevice}
        device={editDevice}
        user={user}
      />
    </div>
  );
}
