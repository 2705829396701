import React, { useState } from 'react';
import { fetchWeatherForecast, analyzeProductionDifference, getEnergyForecastFromChatGPT } from '../../utils/apis/customer';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';

export default function Fault() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [faultForecast, setFaultForecast] = useState([]);
  const [actualProduction, setActualProduction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [showResults, setShowResults] = useState(false);

  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const formatToTurkishDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    setStartDate(formatToTurkishDate(selectedDate));
  };

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;
    setEndDate(formatToTurkishDate(selectedDate));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setFaultForecast([]);
    setActualProduction([]);
    setShowResults(false);

    if (!startDate || !endDate) {
      toast.error('Lütfen hem başlangıç hem de bitiş tarihlerini seçin!');
      return;
    }

    setLoading(true);

    try {
      const weatherData = await fetchWeatherForecast();
      const filteredData = weatherData.list.filter((data) => {
        const date = new Date(data.dt_txt);
        return date >= new Date(startDate.split('.').reverse().join('-')) && date <= new Date(new Date(endDate.split('.').reverse().join('-')).setHours(23, 59, 59, 999));
      });

      const numberOfDays = Math.ceil((new Date(endDate.split('.').reverse().join('-')) - new Date(startDate.split('.').reverse().join('-'))) / (1000 * 60 * 60 * 24)) + 1;

      const chatGptResponse = await getEnergyForecastFromChatGPT(filteredData, numberOfDays);
      setFaultForecast(chatGptResponse);

      const actualProductionData = [
        { datetime: '2024-08-12', energy: 19 },
        { datetime: '2024-08-13', energy: 21 },
        { datetime: '2024-08-14', energy: 16 },
        { datetime: '2024-08-15', energy: 18 },
        { datetime: '2024-08-16', energy: 22 },
        { datetime: '2024-08-17', energy: 17 },
        { datetime: '2024-08-18', energy: 19 },
        { datetime: '2024-08-19', energy: 21 },
        { datetime: '2024-08-20', energy: 16 },
      ];
      setActualProduction(actualProductionData);

      const analysisResult = await analyzeProductionDifference(chatGptResponse, actualProductionData);
      setAnalysis(analysisResult);

      setShowResults(true);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderComparisonTable = () => {
    const rows = faultForecast.map((predicted, index) => {
      const actual = actualProduction.find(a => formatToTurkishDate(a.datetime) === formatToTurkishDate(predicted.date)) || {};
      const difference = actual.energy !== undefined ? (actual.energy - predicted.energy).toFixed(2) : "N/A";

      return (
        <tr key={index}>
          <td>{formatToTurkishDate(predicted.date)}</td>
          <td>{predicted.energy} kW</td>
          <td>{actual.energy !== undefined ? `${actual.energy} kW` : "Veri Yok"}</td>
          <td>{difference !== "N/A" ? `${difference} kW` : "N/A"}</td>
          <td>
            {difference !== "N/A" ? (
              difference > 0 ? <span style={{ color: 'green' }}>▲</span> :
                difference < 0 ? <span style={{ color: 'red' }}>▼</span> :
                  <span style={{ color: 'gray' }}>—</span>
            ) : null}
          </td>
        </tr>
      );
    });

    return (
      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>Tarih</th>
            <th>Tahmin Edilen Üretim</th>
            <th>Gerçek Üretim</th>
            <th>Fark</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };




  const chartOptions = {
    chart: { type: 'bar' },
    xaxis: { categories: faultForecast.map(item => formatToTurkishDate(item.date)) },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            { from: 0, to: 10, color: '#28a745' },
            { from: 10, to: 20, color: '#ffc107' },
            { from: 20, to: 100, color: '#dc3545' }
          ]
        },
        columnWidth: '80%',
      },
    },
    dataLabels: { enabled: false },
    title: { text: 'Tahmin Edilen Enerji Üretimi' },
    series: [{ name: 'Tahmin Edilen Enerji Üretimi (kW)', data: faultForecast.map(item => parseFloat(item.energy)) }]
  };

  const actualChartOptions = {
    chart: { type: 'bar' },
    xaxis: { categories: actualProduction.map(item => formatToTurkishDate(item.datetime)) },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            { from: 0, to: 10, color: '#28a745' },
            { from: 10, to: 20, color: '#ffc107' },
            { from: 20, to: 100, color: '#dc3545' }
          ]
        },
        columnWidth: '80%',
      },
    },
    dataLabels: { enabled: false },
    title: { text: 'Gerçek Enerji Üretimi' },
    series: [{ name: 'Gerçek Enerji Üretimi (kW)', data: actualProduction.map(item => item.energy) }]
  };

  const totalEnergy = faultForecast.reduce((acc, curr) => acc + parseFloat(curr.energy), 0).toFixed(2);
  const averageEnergy = (faultForecast.length ? (totalEnergy / faultForecast.length).toFixed(2) : 0);

  return (
    <div className="container mt-5">
      <h2><i className="ti ti-chart-line"></i> Üretim Analizi</h2>

      <form onSubmit={handleSubmit} className="mt-4">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="startDate" className="form-label">Başlangıç Tarihi</label>
            <input type="date" className="form-control" id="startDate" onChange={handleStartDateChange} min={formattedToday} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="endDate" className="form-label">Bitiş Tarihi</label>
            <input type="date" className="form-control" id="endDate" onChange={handleEndDateChange} min={formattedToday} />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Tahmin Yap</button>
      </form>

      {loading && (
        <div className="mt-4 text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {error && (
        <div className="mt-4 alert alert-danger">
          <h4>Hata:</h4>
          <p>{error}</p>
        </div>
      )}
    

      {showResults && faultForecast.length > 0 && (
        <>
          <div className="card analysis-card shadow-sm p-4 mt-4">
            <h4 className="card-title">Analiz:</h4>
            <p className="card-text">{analysis}</p>
          </div>
          <h4 className="mt-4">Karşılaştırma Tablosu</h4>
          {renderComparisonTable()}
          <div className="mt-4">
        <h4>Enerji Üretim Seviyeleri</h4>
        <div className="d-flex justify-content-around">
          <div className="text-center">
            <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#13deb9' }}></div>
            <p>0-10 kW <br /> (Düşük)</p>
          </div>
          <div className="text-center">
            <div className="text-dark rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#ffc107' }}></div>
            <p>10-20 kW <br /> (Orta)</p>
          </div>
          <div className="text-center">
            <div className="text-white rounded-circle" style={{ width: '30px', height: '30px', display: 'inline-block', backgroundColor: '#dc3545' }}></div>
            <p>20+ kW <br /> (Yüksek)</p>
          </div>
        </div>
      </div>

          <div className="mt-4">
            <h4>Tahmin Sonuçları:</h4>
            <Chart options={chartOptions} series={chartOptions.series} type="bar" height="350" />
            <div className="row mt-4">
              <div className="col-md-6 mb-3">
                <div className="card shadow-sm border-0">
                  <div className="card-body text-center">
                    <h5 className="card-title font-weight-bold text-uppercase">Toplam Enerji Üretimi</h5>
                    <p className="card-text display-5 text-success uretim">{totalEnergy} kW</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card shadow-sm border-0">
                  <div className="card-body text-center">
                    <h5 className="card-title font-weight-bold text-uppercase">Ortalama Enerji Üretimi</h5>
                    <p className="card-text display-5 text-warning uretim">{averageEnergy} kW</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h4>Gerçek Enerji Üretimi:</h4>
            <Chart
              options={actualChartOptions}
              series={actualChartOptions.series}
              type="bar"
              height="350"
            />
          </div>
        </>
      )}
    </div>
  );
}
