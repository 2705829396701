import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { apiPostChangePassword } from '../../utils/apis/customer';
import { useData } from '../../context/Context';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ChangePassword() {
    const { user } = useData();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSave = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("Şifreler eşleşmiyor.");
            return;
        }

        try {
            const data = await apiPostChangePassword(user.token, oldPassword, newPassword, confirmPassword);
            if (data.message === "Parolanız değiştirildi.") {
                toast.success("Parolanız değiştirildi");
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setShowOldPassword(false);
                setShowNewPassword(false);
                setShowConfirmPassword(false);
            } else {
                toast.error(data.errors[0] || "Bilinmeyen bir hata oluştu.");
            }
        } catch (error) {
            toast.error(error.message || "Bir hata oluştu.");
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="w-100">
                    <div className="card shadow p-3 mb-5 bg-body rounded">
                        <h2><i className="ti ti-password"></i> Şifreyi Güncelle</h2>
                        <div className="card-body">
                            <Form onSubmit={handleSave}>
                                <div className="mb-3 password-input-container">
                                    <label htmlFor="oldPassword" className="form-label">Eski Şifre</label>
                                    <input 
                                        type={showOldPassword ? "text" : "password"} 
                                        className="form-control password-input" 
                                        id="oldPassword" 
                                        value={oldPassword} 
                                        onChange={(e) => setOldPassword(e.target.value)} 
                                        required 
                                    />
                                    <span 
                                        className="password-toggle-icon" 
                                        onClick={() => setShowOldPassword(!showOldPassword)} 
                                    >
                                       {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                </div>
                                <div className="mb-3 password-input-container">
                                    <label htmlFor="newPassword" className="form-label">Yeni Şifre</label>
                                    <input 
                                        type={showNewPassword ? "text" : "password"} 
                                        className="form-control password-input" 
                                        id="newPassword" 
                                        value={newPassword} 
                                        onChange={(e) => setNewPassword(e.target.value)} 
                                        required 
                                    />
                                    <span 
                                        className="password-toggle-icon" 
                                        onClick={() => setShowNewPassword(!showNewPassword)} 
                                    >
                                       {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                </div>
                                <div className="mb-3 password-input-container">
                                    <label htmlFor="confirmPassword" className="form-label">Yeni Şifre Tekrarı</label>
                                    <input 
                                        type={showConfirmPassword ? "text" : "password"} 
                                        className="form-control password-input" 
                                        id="confirmPassword" 
                                        value={confirmPassword} 
                                        onChange={(e) => setConfirmPassword(e.target.value)} 
                                        required 
                                    />
                                    <span 
                                        className="password-toggle-icon" 
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)} 
                                    >
                                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                </div>
                                {newPassword !== "" && confirmPassword !== "" && newPassword !== confirmPassword && (
                                    <div className="text-danger mb-3">Şifreler eşleşmiyor</div>
                                )}
                                <Button type="submit" variant="primary">Kaydet</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
