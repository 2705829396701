import { instance } from "./base";
export const apiGetWorkers = async (token) => {
    try {
        const response = await instance.get('/api/Manager/GetWorkerInfo', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Personel bilgileri alınırken bir hata oluştu.');
    }
};

export const apiPostAddNewWorker = async (token, worker) => {
    try {
        const response = await instance.post('/api/Manager/AddWorker', {
            name: worker.name,
            surname: worker.surname,
            email: worker.email,
            phone: worker.phone,
            deviceNumber: worker.deviceNumber
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Yeni personel ekleme başarısız: ${error.message}`);
    }
};


export const apiPutUpdateWorker = async (token, worker) => {
    try {
        const response = await instance.put('/api/Manager/UpdateWorkerInfo', {
            workerId: worker.workerId,
            name: worker.name,
            surname: worker.surname,
            phone: worker.phone,
            email: worker.email,
            deviceName: worker.deviceName,
            locationName: worker.locationName,
            lat: worker.lat,
            lon: worker.lon
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Personel bilgileri güncellenemedi: ${error.message}`);
    }
};

export const apiDeleteWorker = async (token, workerId) => {
    try {
        const response = await instance.delete('/api/Manager/DeleteWorker', {
            headers: { 'Authorization': `Bearer ${token}` },
            data: { workerId }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Personel silme başarısız: ${error.message}`);
    }
};

export const apiGetWorkerDevices = async (token) => {
    try {
        const response = await instance.get('/api/Manager/MGetGetAllDeviceResponse', {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Personel cihazları alınırken bir hata oluştu.');
    }
};
