import { instance } from "./base";

export const apiPostAddNewDevice = async (token, roleId, device) => {
    let url;
    switch (roleId) {
        case 1:
            url = '/api/Admin/AddDeviceForAdmin';
            break;
        case 2:
            url = '/api/Manager/AddDevice';
            break;
        default:
            throw new Error('Geçersiz rol ID');
    }

    try {
        const response = await instance.post(url, device, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Yeni cihaz ekleme başarısız: ${error.message}`);
    }
};



export const apiGetDevices = async (token, roleId, pageNumber = 1, pageSize = 10) => {
    try {
        let url, method, config;
        if (roleId === 1) {
            url = '/api/Admin/GetAllDevice';
            method = 'POST';
            config = {
                headers: { 'Authorization': `Bearer ${token}` },
                data: { pageNumber, pageSize }
            };
        } else if (roleId === 2) {
            url = '/api/Manager/GetListDeviceForManager';
            method = 'GET';
            config = {
                headers: { 'Authorization': `Bearer ${token}` }
            };
        } else {
            url = '/api/Customer/GetMyDevice';
            method = 'GET';
            config = {
                headers: { 'Authorization': `Bearer ${token}` }
            };
        }

        const response = await instance({
            url,
            method,
            ...config
        });

        return roleId === 1 ? { devices: response.data.devices, total: response.data.totalCount } :  { devices: response.data.deviceModels, total: response.data.totalCount };
        
    } catch (error) {
        throw new Error(`Cihazlar getirilemedi: ${error.message}`);
    }
};




export const apiPutUpdateDevice = async (token, roleId, device) => {
    let url;
    switch (roleId) {
        case 1:
            url = '/api/Admin/UpdateDeviceForAdmin';
            break;
        case 2:
            url = '/api/Manager/UpdateDevice';
            break;
        case 3:
            url = '/api/Customer/UpdateDeviceForCustomer';
            break;
        default:
            throw new Error('Geçersiz rol ID');
    }

    try {
        const response = await instance.put(url, {
            deviceId: device.deviceId,
            deviceName: device.name,
            createdDate: device.createdDate,
            status: device.status,
            deviceNumber: device.deviceNumber,
            lat: device.lat,
            lon: device.lon,
            locationName: device.locationName
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Cihaz güncelleme başarısız: ${error.response?.data?.message || error.message}`);
    }
};



export const apiDeleteDevice = async (token, roleId, deviceId) => {
    let url;
    switch (roleId) {
        case 1:
            url = '/api/Admin/DeleteDeviceForAdmin';
            break;
        case 2:
            url = '/api/Manager/DeleteDevice';
            break;
        case 3:
            url = '/api/Customer/DeleteDevice';
            break;
        default:
            throw new Error('Geçersiz rol ID');
    }

    try {
        const response = await instance.delete(url, {
            headers: { 'Authorization': `Bearer ${token}` },
            data: { deviceId }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Cihaz silme başarısız: ${error.message}`);
    }
};
