import React from 'react'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'

export default function CustomerLayout() {
  
  const links = [
    {
      id: 1,
      label: "Ana Sayfa",
      icon: "ti ti-home",
      link: "/"
  },
    {
        id: 2,
        label: "Profilim",
        icon: "ti ti-user",
        link: "/profile"
    },
    {
        id: 3,
        label: "Şifreyi Güncelle",
        icon: "ti ti-password",
        link: "/change-password"  
    }
  
]
  return (
    <Header links={links}>
        <Outlet/>
    </Header>
  )
}
