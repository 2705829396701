import React from 'react';
import ContactForm from '../../components/ContactForm';


export default function Ges() {
  return (
    <div>
      <div className="hero-area home-3" data-scroll-index={0}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-content">
                <h3>Güneşin ışığında geleceğe aydınlık adımlar!</h3>
                <p>Yenilenebilir enerjiye geçişin lideri, Güneş enerjisi dönüşüm sistemleri.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero Area End! */}
      {/* Feature Area Start */}
      <div className="feature-area home-3" data-scroll-index={1}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="section-title mt-4">
                <h2>Güneşin gücüyle yenilen!</h2>
                <p>Güneş enerjisiyle çalışan çevre dostu elektrik ünitesi, enerjiyi verimli kullanır.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="feature-single-image">
                <img src="themes/ges/assets/img/gezgin.png" alt="image" />
              </div>
              <div className="row mt-5">
                {/* Single Feature Box */}
                <div className="col-md-6">
                  <div className="feature-box">
                    <div className="feature-icon">
                      <img src="themes/ges/assets/img/icon-img1.png" alt="image" />
                    </div>
                    <h4>Çevre Dostu</h4>
                    <p>Karbon emisyonlarını azaltarak çevreye olumlu etki sağlayan yenilenebilir enerji kaynağı.</p>
                  </div>
                </div>
                {/* Single Feature Box */}
                {/* Single Feature Box */}
                <div className="col-md-6">
                  <div className="feature-box">
                    <div className="feature-icon">
                      <img src="themes/ges/assets/img/icon-img2.png" alt="image" />
                    </div>
                    <h4>Kolay Kurulum</h4>
                    <p>Basit montajı sayesinde hızlı ve kolayca kurulabilir.</p>
                  </div>
                </div>
                {/* Single Feature Box */}
                {/* Single Feature Box */}
                <div className="col-md-6">
                  <div className="feature-box">
                    <div className="feature-icon">
                      <img src="themes/ges/assets/img/icon-img3.png" alt="image" />
                    </div>
                    <h4>Yüksek Verimlilik</h4>
                    <p>Güneş enerjisini en verimli şekilde elektriğe dönüştürme yeteneği.</p>
                  </div>
                </div>
                {/* Single Feature Box */}
                {/* Single Feature Box */}
                <div className="col-md-6">
                  <div className="feature-box">
                    <div className="feature-icon">
                      <img src="themes/ges/assets/img/icon-img4.png" alt="image" />
                    </div>
                    <h4>Uzun Ömür</h4>
                    <p>Dayanıklı malzemelerden yapılmış olup uzun süreli kullanım sağlar, bakım ihtiyacı düşüktür.</p>
                  </div>
                </div>
                {/* Single Feature Box */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature Area End! */}
      {/* Overview Area Start */}
      <div className="overvew-area home-3 section-padding" data-scroll-index={2}>
        <div className="container">
          <div className="signle-overview one pd-70">
            <div className="row">
              <div className="col-md-6">
                <div className="overview-content pd-80">
                  <div className="overview-icon">
                    <i className="fa fa-commenting-o" aria-hidden="true" />
                  </div>
                  <h2>Müşterilerle hızlı mesajlaşma</h2>
                  <p>Müşterilerle anında iletişim imkanı sağlayarak, her an her yerde güneş enerjisiyle çalışan elektrik üniteleri aracılığıyla çevre dostu ve verimli enerjiyi mümkün kılar.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="overview-image wide-box">
                  <img src="themes/ges/assets/img/gezgin2.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="signle-overview two">
            <div className="row">
              <div className="col-md-6">
                <div className="overview-image">
                  <img src="themes/ges/assets/img/gezgin3.png" alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="overview-content">
                  <div className="overview-icon">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                  </div>
                  <h2>Yönetim kolaylığı<br /> sizin kontrolünüzde</h2>
                  <p>Kolay yönetim ve esneklik, işlerinizi sizin kontrolünüzde verimli bir şekilde yönlendirir, gücü elinizde tutarsınız.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overview Area End! */}
      {/* pricing Area Start */}
      {/* pricing Area End! */}
      {/* Testimonial Area Start */}
      <div className="testimonail-area home-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Testimonial content */}
            </div>
          </div>
          <div className="testimonial-main">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div id="testimonial-active" className="testimonial-wrapper owl-carousel">
                    {/* Testimonials */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ & Contact Area Starts */}
      <div className="faq-contact-area home-3 section-padding" data-scroll-index={4}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Hemen Teklif Al</h2>
              </div>
            </div>
          </div>
          <div className="row pt-20 mt-5">
            <div className="col-md-10 offset-md-1">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
