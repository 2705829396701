import React, { useEffect, useState } from 'react';
import { FaDatabase, FaCheckCircle, FaBuilding, FaUsers, FaBell, FaTools, FaPlusCircle, FaExclamationTriangle } from 'react-icons/fa';
import Chart from 'react-apexcharts';
import { apiGetTotalDevices, apiGetUsedDevicesCount, apiGetTotalManagers, apiGetTotalEmployees } from '../../utils/apis/admin';
import { useData } from '../../context/Context';
import toast from 'react-hot-toast';

export default function Admin() {
  const { user } = useData();
  const [totalDevices, setTotalDevices] = useState(0);
  const [usedDevices, setUsedDevices] = useState(0);
  const [totalManagers, setTotalManagers] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [totalDevicesData, usedDevicesData, totalManagersData, totalEmployeesData] = await Promise.all([
          apiGetTotalDevices(user.token),
          apiGetUsedDevicesCount(user.token),
          apiGetTotalManagers(user.token),
          apiGetTotalEmployees(user.token)
        ]);
        setTotalDevices(totalDevicesData.totalDevices);
        setUsedDevices(usedDevicesData.usedDevicesCount);
        setTotalManagers(totalManagersData.totalManagers);
        setTotalEmployees(totalEmployeesData.totalWorkers);
      } catch (error) {
        setError(error.message);
        toast.error("Veriler alınırken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.token]);

  const options = {
    chart: {
      type: 'pie',
    },
    labels: ['Aktif', 'Pasif', 'Arızalı'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const series = [44, 55, 13];

  return (
    <div className="container my-4">
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Yükleniyor...</span>
          </div>
        </div>
      ) : error ? (
        <p>Hata: {error}</p>
      ) : (
        <>
          <div className="row">
            <div className="col-md-3">
              <div className="info-card bg-primary text-white">
                <div className="info-card-body">
                  <FaDatabase className="info-card-icon" />
                  <h3>{totalDevices}</h3>
                  <p>Toplam Cihaz</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-card bg-success text-white">
                <div className="info-card-body">
                  <FaCheckCircle className="info-card-icon" />
                  <h3>{usedDevices}</h3>
                  <p>Kullanılan Cihaz</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-card bg-warning text-white">
                <div className="info-card-body">
                  <FaBuilding className="info-card-icon" />
                  <h3>{totalManagers}</h3>
                  <p>Toplam Firma Sayısı</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="info-card bg-danger text-white">
                <div className="info-card-body">
                  <FaUsers className="info-card-icon" />
                  <h3>{totalEmployees}</h3>
                  <p>Toplam Personel</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Cihaz İstatistikleri</h4>
                </div>
                <div className="card-body">
                  <div className="chart-container">
                    <Chart options={options} series={series} type="pie" width="380" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
