import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function Pagination({ count, page, onChange }) {
    const siblingCount = 3;

    const handleNext = () => {
        if (page < count) {
            onChange(page + 1);
        }
    };

    const handlePrev = () => {
        if (page > 1) {
            onChange(page - 1);
        }
    };

    const pageRangeStart = Math.max(
        1,
        Math.min(page - Math.floor(siblingCount / 2), count - siblingCount + 1)
    );

    const pageRangeEnd = Math.min(count, pageRangeStart + siblingCount - 1);

    const pages = [];
    for (let p = pageRangeStart; p <= pageRangeEnd; p++) {
        pages.push(p);
    }

    return (
        <div className="pagination">
            <button className="btn" disabled={page === 1} onClick={handlePrev}>
                <IoIosArrowBack />
            </button>
            {count > siblingCount && (
                <>
                    {pageRangeStart > 1 && (
                        <>
                            <button className="btn" onClick={() => onChange(1)}>1</button>
                            {pageRangeStart > 2 && <span className="dots">...</span>}
                        </>
                    )}
                </>
            )}
            {pages.map((p) => (
                <button
                    key={p}
                    className={`btn ${page === p ? "active" : ""}`}
                    onClick={() => onChange(p)}
                >
                    {p}
                </button>
            ))}
            {count > siblingCount && (
                <>
                    {pageRangeEnd < count && (
                        <>
                            {pageRangeEnd < count - 1 && <span className="dots">...</span>}
                            <button className="btn" onClick={() => onChange(count)}>{count}</button>
                        </>
                    )}
                </>
            )}
            <button className="btn" disabled={page === count} onClick={handleNext}>
                <IoIosArrowForward />
            </button>
        </div>
    );
}
