import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { apiGetCareTypeList, apiPostAddNewMaintenance, apiGetCareList, apiDeleteCare, apiPutUpdateCare } from '../../utils/apis/customer';
import { useData } from '../../context/Context';
import toast from 'react-hot-toast';
import DeleteCareModal from '../../components/DeleteCareModal';
import EditCareModal from '../../components/EditCareModal';

export default function Care() {
  const [selectedMaintenance, setSelectedMaintenance] = useState('');
  const [maintenanceDetails, setMaintenanceDetails] = useState('');
  const [maintenanceDate, setMaintenanceDate] = useState('');
  const [maintenances, setMaintenances] = useState([]);
  const [careTypes, setCareTypes] = useState([]);
  const [careList, setCareList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCareId, setSelectedCareId] = useState(null);
  const [selectedCare, setSelectedCare] = useState(null);

  const { user } = useData();
  const token = user ? user.token : '';
  const roleId = user ? user.roleId : null;

  useEffect(() => {
    const fetchCareTypes = async () => {
      try {
        const careTypeList = await apiGetCareTypeList(token);
        setCareTypes(careTypeList);
      } catch (error) {
        console.error(error.message);
      }
    };

    const fetchCareList = async () => {
      try {
        const careList = await apiGetCareList(token, roleId);
        setCareList(careList);
      } catch (error) {
        console.error(error.message);
      }
    };

    if (token && roleId) {
      fetchCareTypes();
      fetchCareList();
    }
  }, [token, roleId]);

  const maintenanceColors = {
    1: '#FFEB3B', 
    2: '#2196F3', 
    3: '#4CAF50'  
  };

  const handleSelectChange = (e) => {
    setSelectedMaintenance(e.target.value);
  };

  const handleDetailsChange = (e) => {
    setMaintenanceDetails(e.target.value);
  };

  const handleDateChange = (e) => {
    setMaintenanceDate(e.target.value);
  };

  const handleAddMaintenance = async () => {
    if (!selectedMaintenance || !maintenanceDetails || !maintenanceDate) {
      toast.error("Lütfen boş alanları doldurun");
      return;
    }

    const selectedCareType = careTypes.find(careType => careType.name === selectedMaintenance);
    const newMaintenance = {
      careTypeId: selectedCareType ? selectedCareType.id : 0,
      careDate: maintenanceDate,
      details: maintenanceDetails
    };

    try {
      const response = await apiPostAddNewMaintenance(token, newMaintenance, roleId);
      setMaintenances([...maintenances, { type: selectedMaintenance, details: maintenanceDetails, date: maintenanceDate }]);
      setSelectedMaintenance('');
      setMaintenanceDetails('');
      setMaintenanceDate('');
      toast.success('Bakım başarıyla eklendi');

      const updatedCareList = await apiGetCareList(token, roleId);
      setCareList(updatedCareList);
    } catch (error) {
      toast.error(`Bakım ekleme hatası: ${error.message}`);
    }
  };

  const handleEditMaintenance = (index) => {
    setSelectedCare(careList[index]);
    setShowEditModal(true);
  };

  const handleUpdateCare = async (updatedCare) => {
    try {
      const response = await apiPutUpdateCare(token, updatedCare);
      toast.success(response.message || 'Bakım başarıyla güncellendi.');
      setShowEditModal(false);

      const updatedCareList = await apiGetCareList(token, roleId);
      setCareList(updatedCareList);
    } catch (error) {
      toast.error(`Bakım güncelleme hatası: ${error.message}`);
    }
  };

  const handleDeleteMaintenance = async () => {
    try {
      await apiDeleteCare(token, selectedCareId);
      toast.success('Bakım başarıyla silindi.');
      setShowDeleteModal(false);

      const updatedCareList = await apiGetCareList(token, roleId);
      setCareList(updatedCareList);
    } catch (error) {
      toast.error(`Bakım silme hatası: ${error.message}`);
    }
  };

  const openDeleteModal = (care) => {
    setSelectedCare(care);
    setSelectedCareId(care.id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedCareId(null);
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('tr-TR', options);
  };

  return (
    <div className="container mt-5">
      <h2><i className='ti ti-tools'></i>Bakım Kontrolü</h2>
      <div className="mb-3">
        <label htmlFor="maintenanceType" className="form-label">Bakım Türü</label>
        <select
          id="maintenanceType"
          className="form-select"
          value={selectedMaintenance}
          onChange={handleSelectChange}
        >
          <option value="">Bakım Türünü Seçin</option>
          {careTypes.map((careType) => (
            <option key={careType.id} value={careType.name}>
              {careType.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="maintenanceDate" className="form-label">Bakım Tarihi</label>
        <input
          type="date"
          id="maintenanceDate"
          className="form-control"
          value={maintenanceDate}
          onChange={handleDateChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="maintenanceDetails" className="form-label">Bakım Detayları</label>
        <textarea
          id="maintenanceDetails"
          className="form-control"
          rows="5"
          value={maintenanceDetails}
          onChange={handleDetailsChange}
          style={{ resize: 'none' }}
        ></textarea>
      </div>
      <button className="btn btn-primary mb-3" onClick={handleAddMaintenance}>Bakım Ekle</button>

      <h3 className="mt-4">Bakım Listesi</h3>
      <div className="row">
        {careList.map((care, index) => (
          <div key={index} className="col-12 mb-3">
            <div className="card" style={{ borderLeft: `5px solid ${maintenanceColors[care.careTypeId] || '#000'}` }}>
              <div className="card-body">
                <h5 className="card-title">{care.careTypeName}</h5>
                <p className="card-text">{care.details}</p>
                <p className="card-text"><small className="text-muted">Son Gün: {formatDate(care.careDate)}</small></p>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-sm me-2 d-flex align-items-center"
                    style={{ backgroundColor: '#17a2b8', color: 'white' }}
                    onClick={() => handleEditMaintenance(index)}
                  >
                    <FaEdit className="me-1" /> Düzenle
                  </button>
                  <button
                    className="btn btn-sm d-flex align-items-center"
                    style={{ backgroundColor: '#dc3545', color: 'white' }}
                    onClick={() => openDeleteModal(care)}
                  >
                    <FaTrash className="me-1" /> Sil
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <DeleteCareModal
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        handleConfirm={handleDeleteMaintenance}
        careTypeName={selectedCare?.careTypeName}
      />
      <EditCareModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleConfirm={handleUpdateCare}
        care={selectedCare}
        careTypes={careTypes}
      />
    </div>
  );
}
