import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from "./routes/routes";
import React from "react";
import { ContextProvider } from "./context/Context";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from '@react-oauth/google';


const routing = createBrowserRouter(router);

function App() {
  return (
    <GoogleOAuthProvider clientId="197667809425-l2raf8gnjlrcsp268fenf430d9maqpa6.apps.googleusercontent.com">
      <ContextProvider>
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 2000,
          }}
        />
        <React.Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={routing} />
        </React.Suspense>
      </ContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
