import React from 'react'
import ContactForm from '../../components/ContactForm'

export default function Contact() {
  return (
    <div>
    <div className="hero-area home-6" data-scroll-index={0}>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="hero-content">
              <h3>Bizimle İletişime Geçin</h3>
              <p>Sorularınız mı var? Daha fazla bilgi mi almak istiyorsunuz? Güneş enerjisi çözümlerimizle ilgili her türlü talep ve geri bildirimleriniz için bizimle iletişime geçin. Uzman ekibimiz, ihtiyaçlarınıza en uygun çözümleri sunmak ve size en iyi hizmeti sağlamak için burada. Aşağıdaki formu doldurarak veya iletişim bilgilerimizi kullanarak bize ulaşabilirsiniz. Size yardımcı olmaktan memnuniyet duyarız.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="feature-area home-3" data-scroll-index={1}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="section-title mt-4">
                <h2>Geleceğin Enerji Çözümleri İçin Bizimle İletişime Geçin</h2>
              </div>
            </div>

          </div>
        </div>
      </div>
  

    {/* FAQ & Conatct Area Starts*/}
    <div className="faq-contact-area home-3 section-padding" data-scroll-index={4}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
    
            </div>
          </div>
        </div>
        <div className="row pt-20">
          <div className="col-md-10 offset-md-1">
            <ContactForm/>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
