import React, { useState, useEffect } from 'react';
import { useData } from '../../context/Context';
import { apiPutUserInfo } from '../../utils/apis/customer';
import { toast } from 'react-hot-toast';

export default function CustomerProfile() {
  const { user, login } = useData();
  const [userData, setUserData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    setUserData({
      name: user?.name || "",
      surname: user?.surname || "",
      phone: user?.phone || "",
      email: user?.email || "",
    });
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData.phone.length !== 11) {
      setPhoneError("Telefon numarası 11 haneli olmalıdır.");
      return;
    }

    try {
      const data = await apiPutUserInfo(user.token, userData);

      if (data.message === "kullanıcı bilgileri güncellendi") {
        login({
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          token: user.token,
          roleId: user.roleId
        });
        setEdit(false);
        toast.success("Kullanıcı bilgileri güncellendi");
        setPhoneError('');
      } else {
        toast.error('Beklenmedik yanıt: ' + data.message);
        console.error('Beklenmedik yanıt:', data);
      }
    } catch (error) {
      toast.error('Kullanıcı bilgileri güncellenemedi: ' + error.message);
      console.error('Kullanıcı bilgileri güncellenemedi:', error.message);
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (/^\d*$/.test(phone)) {
      setUserData({ ...userData, phone });
    }
    if (phone.length !== 11) {
      setPhoneError("Telefon numarası 11 haneli olmalıdır.");
    } else {
      setPhoneError('');
    }
  };

  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title fw-semibold mb-4">Kullanıcı Bilgilerim</h5>
            <div className="card">
              <div className="card-body">
                {userData && (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputtext1" className="form-label">Adınız</label>
                      <input
                        required
                        readOnly={!edit}
                        type="text"
                        className="form-control"
                        id="exampleInputtext1"
                        aria-describedby="textHelp"
                        value={userData.name}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputtext2" className="form-label">Soyadınız</label>
                      <input
                        required
                        readOnly={!edit}
                        type="text"
                        className="form-control"
                        id="exampleInputtext2"
                        aria-describedby="textHelp"
                        value={userData.surname}
                        onChange={(e) => setUserData({ ...userData, surname: e.target.value })}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputPhone1" className="form-label">Telefon Numarası</label>
                      <input
                        required
                        readOnly={!edit}
                        type="tel"
                        className="form-control"
                        id="exampleInputPhone1"
                        aria-describedby="phoneHelp"
                        value={userData.phone}
                        onChange={handlePhoneChange}
                        pattern="\d{11}"
                        maxLength="11"
                        placeholder="Telefon numarası giriniz"
                      />
                      {phoneError && <div className="text-danger">{phoneError}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">E-Posta</label>
                      <input
                        required
                        readOnly={!edit}
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      />
                    </div>
                    {edit && <button type="submit" className="btn btn-primary mr-2">Kaydet</button>}
                    <button type="button" className={edit ? "btn btn-danger" : "btn btn-success"} onClick={() => setEdit(!edit)}>{edit ? "İptal" : "Düzenle"}</button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
